<template>
    <div class="flex flex-col | automation-wrapper">
        <warning
            icon="regular/video-game-magic-wand"
            :title="$t('error.warning')"
            :content="$t('error.selectAccountWarning')"
            v-if="!validAccount"
        />
        <template v-else>
            <portal to="content-header-left">
                <activix-reload :loading="isLoading" @click="fetchAutomations" />
            </portal>

            <div class="box" :class="{ loading: isLoading }">
                <div class="box-header| h-auto p-6 flex justify-between">
                    <activix-button type="primary" @click="onCreate">
                        <icon name="bold/add" class="mr-2 text-sm" />
                        {{ $t('general.add') }}
                    </activix-button>
                    <input
                        class="form-control w-auto"
                        type="text"
                        :placeholder="$t('search.search')"
                        v-model="tableState.searchToken"
                    />
                </div>
                <div class="box-body | p-0">
                    <el-table
                        class="w-full border-t-2 border-b"
                        :border="true"
                        :data="paginatedAutomations"
                        :default-sort="{ prop: 'name', order: 'ascending' }"
                        :stripe="true"
                        @sort-change="updateTableSorting"
                    >
                        <el-table-column
                            header-align="center"
                            prop="name"
                            :label="$t('automations.table.headings.title')"
                            :sortable="true"
                        >
                            <template slot-scope="scope">
                                <router-link :to="{ name: 'automations.update', params: { id: scope.row.id } }">
                                    {{ scope.row.name }}
                                </router-link>
                            </template>
                        </el-table-column>
                        <el-table-column
                            align="center"
                            prop="modelEvent"
                            :label="$t('automations.table.headings.event')"
                            :sortable="true"
                        />
                        <el-table-column
                            align="center"
                            prop="created_at"
                            :label="$t('automations.table.headings.createdAt')"
                            :sortable="true"
                            :formatter="handleTableDateTimeFormat"
                        />
                        <el-table-column
                            align="center"
                            prop="created_by"
                            :label="$t('automations.table.headings.createdBy')"
                            :sortable="true"
                            :formatter="formatCreatedBy"
                        />
                        <el-table-column
                            align="center"
                            prop="updated_at"
                            :label="$t('automations.table.headings.updatedAt')"
                            :sortable="true"
                            :formatter="handleTableDateTimeFormat"
                        />
                        <el-table-column
                            align="center"
                            prop="updated_by"
                            :label="$t('automations.table.headings.updatedBy')"
                            :sortable="true"
                            :formatter="formatUpdatedBy"
                        />
                        <el-table-column
                            align="center"
                            prop="affected_models"
                            :label="$t('automations.table.headings.affectedClients')"
                            :sortable="true"
                        >
                            <template slot-scope="scope">
                                <div class="flex items-center justify-center">
                                    <span class="inline-block" :class="automationStateClass(scope.row)" v-text="scope.row.affected_models" />
                                    <icon
                                        name="regular/delete-1"
                                        class="ml-2 link-grey-light | hover:text-red-500"
                                        @click="cancelValidation(scope.row)"
                                        v-if="scope.row.retroactionInProgress"
                                    />
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column
                            align="center"
                            prop="active"
                            width="80"
                            :label="$t('automations.table.headings.active')"
                            :sortable="true"
                        >
                            <template slot-scope="scope">
                                <activix-tooltip
                                    :content="scope.row.activationTooltip"
                                    :disabled="!scope.row.activationTooltip"
                                >
                                    <activix-switcher
                                        class="inline-flex"
                                        :disabled="!scope.row.canToggleActivation"
                                        :value="scope.row.active"
                                        @input="toggleActive(scope.row, $event)"
                                    />
                                </activix-tooltip>
                            </template>
                        </el-table-column>
                        <el-table-column
                            align="center"
                            prop="visible"
                            width="80"
                            :label="$t('automations.visible')"
                            :sortable="true"
                            v-if="authUser.isAdmin()"
                        >
                            <template slot-scope="scope">
                                <activix-tooltip
                                    :content="$t('automations.systemAutomationNotVisible')"
                                >
                                    <activix-switcher
                                        class="inline-flex"
                                        :disabled="!!scope.row.system_type_id"
                                        :value="!!scope.row.system_type_id ? false : scope.row.visible"
                                        @input="toggleVisiblity(scope.row, $event)"
                                    />
                                </activix-tooltip>
                            </template>
                        </el-table-column>
                        <el-table-column align="center" column-key="delete" width="50">
                            <template slot-scope="scope">
                                <activix-tooltip :content="$t('general.delete')">
                                    <icon
                                        :name="$icons.trash"
                                        class="link-grey | hover:text-red-500"
                                        @click="onDelete(scope.row.id)"
                                        v-if="authUser.isAdmin() || (!!scope.row.created_by && authUser.isSameUser(scope.row.created_by.id))"
                                    />
                                </activix-tooltip>
                            </template>
                        </el-table-column>
                    </el-table>
                    <el-pagination
                        class="p-6"
                        :layout="paginationLayout"
                        :background="true"
                        :current-page.sync="tableState.currentPage"
                        :page-size.sync="tableState.perPage"
                        :page-sizes="tableState.pageOptions"
                        :total="filteredSortedAutomations.length"
                        @size-change="updateTablePerPage"
                        v-if="tableData.length > 0"
                    />
                </div>
            </div>
        </template>

        <activix-confirm-modal
            type="warning"
            :content="activateModal.content"
            :opened.sync="activateModal.opened"
            @approve="activateAutomation(true)"
            @deny="activateAutomation(false)"
        />

        <activix-confirm-modal
            type="warning"
            :content="$t('automations.deleteModal')"
            :opened.sync="deleteModal.opened"
            @approve="deleteAutomation"
            @closed="deleteModal.automationId = null"
        />

        <activix-confirm-modal
            type="warning"
            :title="$t('automations.disableAutomation')"
            :content="$t('automations.deactivateAutomationModal')"
            :opened.sync="disableAutomationWarningModal.opened"
            @approve="toggleActivation(disableAutomationWarningModal.row, disableAutomationWarningModal.state)"
        />

        <activix-confirm-modal
            type="warning"
            :content="$t('automations.deactivateModal')"
            :opened.sync="deactivateModal.opened"
            @approve="deactivateAutomation(true)"
            @deny="deactivateAutomation(false)"
        />
    </div>
</template>

<script>
    import { get, orderBy } from 'lodash-es';

    import { mapState } from 'pinia';
    import TrackView from '../../mixins/TrackView.js';
    import DataTableMixin from '../../mixins/DataTable.js';

    import Warning from '../../components/Warning.vue';
    import Automation from '../../entities/Automation.js';
    import User from '../../entities/User.js';
    import Account from '../../entities/Account.js';

    // Pinia
    import { useContextStore } from '../../store/modules/context/store.js';
    import { useGlobalStore } from '../../store/store.js';

    export default {
        name: 'AutomationsList',

        components: {
            Warning,
        },

        mixins: [TrackView, DataTableMixin],

        data() {
            return {
                automations: [],
                activateModal: {
                    opened: false,
                    automationId: null,
                    content: '',
                },
                deleteModal: {
                    opened: false,
                    automationId: null,
                },
                deactivateModal: {
                    opened: false,
                    automationId: null,
                },
                disableAutomationWarningModal: {
                    opened: false,
                    row: null,
                    state: null,
                },
            };
        },

        computed: {
            ...mapState(useGlobalStore, ['authUser']),
            ...mapState(useContextStore, {
                contextChildAccount: 'contextChildAccount',
                contextAccount: 'account',
            }),

            validAccount() {
                return get(this.contextChildAccount, 'id');
            },

            isLoading() {
                return this.$wait.is(['fetching.contextAccount', 'fetching.automations']);
            },

            filteredSortedAutomations() {
                const searchedData = this.searchData(this.tableState.searchToken, this.tableData);

                return orderBy(searchedData, [this.tableState.sorting.column], [this.tableState.sorting.order]);
            },

            paginatedAutomations() {
                return this.filteredSortedAutomations.slice(
                    (this.tableState.currentPage - 1) * this.tableState.perPage,
                    this.tableState.currentPage * this.tableState.perPage,
                );
            },

            tableData() {
                if (!this.validAccount) {
                    return [];
                }

                return this.automations
                    .map(automation => {
                        const affectedModels = automation.affected_models || 0;
                        let activationTooltip = '';

                        if (!this.contextAccount.automation) {
                            activationTooltip = this.$t('automations.automationMustBeActiveInAccount');
                        } else if (automation.hasInvalidCriterion) {
                            activationTooltip = this.$t('automations.activeDisabledByInvalidCriterion');
                        } else if (!automation.atLeastOneActionActive) {
                            activationTooltip = this.$t('automations.activeDisabledByNoActiveAction');
                        }

                        return {
                            id: automation.id,
                            name: automation.name || this.$t('automations.table.row.noName'),
                            modelEvent: this.$t(`automations.trigger.${automation.model}.${automation.event}`),
                            model: automation.model,
                            event: automation.event,
                            created_at: automation.created_at || '-',
                            created_by: automation.created_by,
                            updated_at: automation.automationUpdatedAt,
                            updated_by: automation.automationUpdatedBy,
                            retroaction_execution_start_at: automation.retroaction_execution_start_at,
                            retroaction_execution_end_at: automation.retroaction_execution_end_at,
                            active: automation.active,
                            system_type_id: automation.system_type_id,
                            affected_models: affectedModels,
                            hasExecutionField: automation.hasExecutionField,
                            hasDelay: automation.hasDelay,
                            noRetroaction: automation.noRetroaction,
                            retroactionCompleted: automation.retroactionCompleted,
                            retroactionInProgress: automation.retroactionInProgress,
                            canToggleActivation:
                                !automation.system_type_id &&
                                !automation.retroactionInProgress &&
                                this.contextAccount.automation &&
                                !automation.hasInvalidCriterion &&
                                automation.atLeastOneActionActive,
                            activationTooltip,
                            visible: automation.visible,
                        };
                    })
                    .filter(automation => this.authUser.isAdmin() || (automation.visible && !automation.system_type_id));
            },
        },

        watch: {
            'contextAccount.id': {
                immediate: true,
                handler() {
                    this.fetchAutomations();
                },
            },
        },

        methods: {
            onCreate() {
                this.$router.push({ name: 'automations.store' });
            },

            toggleActive(automationRow, automationState) {
                if (!automationState) {
                    this.disableAutomationWarningModal.row = automationRow;
                    this.disableAutomationWarningModal.state = automationState;
                    this.disableAutomationWarningModal.opened = true;
                    return;
                }

                this.toggleActivation(automationRow, automationState);
            },

            onDelete(id) {
                this.deleteModal.automationId = id;
                this.deleteModal.opened = true;
            },

            toggleActivation(automationData, newValue) {
                if (newValue && automationData.affected_models == 0 && automationData.hasExecutionField) {
                    this.activateModal.automationId = automationData.id;
                    this.activateModal.content = this.$t('automations.retroactionModal');
                    this.activateModal.opened = true;
                } else if (
                    !newValue &&
                    automationData.affected_models > 0 &&
                    (automationData.hasExecutionField || automationData.hasDelay)
                ) {
                    this.deactivateModal.automationId = automationData.id;
                    this.deactivateModal.opened = true;
                } else {
                    this.updateAutomation(automationData.id, { active: newValue });
                }
            },

            toggleVisiblity(automationData, newValue) {
                this.updateAutomation(automationData.id, { visible: newValue });
            },

            formatCreatedBy(row) {
                const user = new User(row.created_by);

                if (!user) {
                    return '-';
                }

                if (user.account_id == Account.ACTIVIX && !this.authUser.isAdmin()) {
                    return 'Activix';
                }

                return user.fullName;
            },

            formatUpdatedBy(row) {
                const user = new User(row.updated_by);

                if (!user) {
                    return '-';
                }

                if (user.account_id == Account.ACTIVIX && !this.authUser.isAdmin()) {
                    return 'Activix';
                }

                return user.fullName;
            },

            automationStateClass(row) {
                if (row.retroactionCompleted) {
                    return 'loading-dots-ended';
                }

                if (row.retroactionInProgress) {
                    return 'loading-dots';
                }

                return '';
            },

            listenToAccount() {
                if (!this.$broadcasting.channels.account) {
                    return;
                }

                this.$broadcasting.channels.account.listen('Automation\\AutomationRetroactionChangeState', data => {
                    const automation = this.automations.find(automation => automation.id == data.automationId);

                    if (automation) {
                        automation.retroaction_execution_start_at = data.startAt;
                        automation.retroaction_execution_end_at = data.endAt;
                    }
                });
            },

            listenToUser() {
                if (!this.$broadcasting.channels.user) {
                    return;
                }

                this.$broadcasting.channels.user.listen('Automation\\UpdateAffectedModels', data => {
                    const automation = this.automations.find(automation => automation.id == data.automationId);

                    if (automation) {
                        automation.affected_models = data.affectedModels;
                    }
                });
            },

            async fetchAutomations() {
                if (!this.validAccount) {
                    return;
                }

                this.$wait.start('fetching.automations');

                try {
                    this.automations = (await this.$api.automations.index({ accountId: this.contextAccount.id })).map(
                        automation => new Automation(automation),
                    );

                    this.$wait.end('fetching.automations');
                } catch (error) {
                    this.$wait.end('fetching.automations');
                    this.$notify.error(this.$t('automations.alerts.index.error'));

                    throw error;
                }
            },

            async updateAutomation(id, payload) {
                const automation = this.automations.find(a => a.id == id);

                if (automation) {
                    Object.assign(automation, payload);
                }

                try {
                    await this.$api.automations.update(id, payload);
                } catch (error) {
                    this.$notify.error(this.$t('automations.alerts.update.error'));

                    throw error;
                }
            },

            async cancelValidation(row) {
                this.$wait.start('updating.automation');

                if (!row.id) {
                    return;
                }

                try {
                    const response = await this.$api.automations.update(row.id, { cancelValidation: true });
                    const automation = this.automations.find(automation => automation.id == row.id);

                    if (automation) {
                        automation.retroaction_execution_end_at = response.retroaction_execution_end_at;
                        automation.retroaction_execution_start_at = response.retroaction_execution_start_at;
                    }

                    this.$wait.end('updating.automation');
                } catch (error) {
                    this.$wait.end('updating.automation');
                    this.$notify.error(this.$t('automations.alerts.update.error'));

                    throw error;
                }
            },

            activateAutomation(retroactive) {
                const payload = {
                    active: true,
                    hasRetroactive: retroactive,
                };

                this.updateAutomation(this.activateModal.automationId, payload);

                this.activateModal.automationId = null;
                this.activateModal.content = null;
            },

            deactivateAutomation(deletePending) {
                const payload = {
                    active: false,
                    deletePendingAutomation: deletePending,
                };

                this.updateAutomation(this.deactivateModal.automationId, payload);

                this.deactivateModal.automationId = null;
            },

            async deleteAutomation() {
                const id = this.deleteModal.automationId;

                if (!id) {
                    return;
                }

                const index = this.automations.findIndex(automation => automation.id == id);

                if (index !== -1) {
                    this.automations.splice(index, 1);
                }

                try {
                    await this.$api.automations.destroy(id);
                } catch (error) {
                    this.$notify.error(this.$t('automations.alerts.destroy.error'));

                    throw error;
                }
            },
        },

        created() {
            this.listenToAccount();
            this.listenToUser();

            this.$broadcasting.$on('account-channel-ready', this.listenToAccount);
            this.$broadcasting.$on('user-channel-ready', this.listenToUser);
        },

        beforeDestroy() {
            this.$broadcasting.$off('account-channel-ready', this.listenToAccount);
            this.$broadcasting.$off('user-channel-ready', this.listenToUser);
        },
    };
</script>
